import React from 'react';
import {T} from "@deity-io/falcon-i18n";
import info from '../../../assets/img/info.svg';
import checkGreen from '../../../assets/img/checkmark_green.svg';
import notAvailable from '../../../assets/img/not_available.svg';

const Availbility = ({availbility}) => {
    const renderCheckMark = value => {
        if (+value === 0) return <img src={notAvailable} alt="unavailable"/>;
        if (+value === 1) return <img src={checkGreen} alt="available"/>;
        if (+value > 1) return <img src={notAvailable} alt="partially-available"/>;
    };


    return (
        <div>
            <ul className="delivery-accordion__content-header delivery-accordion-row">
                <li>
                    <T id="product.shop"/>
                </li>
                <li className="delivery-accordion__menu-with-icon">
            <span>
              <T id="product.availability"/>
            </span>
                    <img src={info} alt="delivery-info" className="delivery-accordion__icon"/>
                    <div className="delivery-accordion__tooltip">
                        <T id="product.availabilityDetails"/>
                    </div>
                </li>
            {/*    <li className="delivery-accordion__menu-with-icon">*/}
            {/*<span>*/}
            {/*  <T id="product.availabilityCarryout"/>*/}
            {/*</span>*/}
            {/*        <img src={info} alt="delivery-info" className="delivery-accordion__icon"/>*/}
            {/*        <div className="delivery-accordion__tooltip">*/}
            {/*            <T id="product.availabilityCarryoutDetails"/>*/}
            {/*        </div>*/}
            {/*    </li>*/}
                <li className="delivery-accordion__menu-with-icon">
            <span>
              <T id="product.availabilityExposed"/>
            </span>
                    <img src={info} alt="" className="delivery-accordion__icon"/>
                    <div className="delivery-accordion__tooltip">
                        <T id="product.availabilityExposedDetails"/>
                    </div>
                </li>
            </ul>

            {
                availbility.map(({available, deliveryFrom, isExposed, siteName}) => (
                    <ul key={siteName} className="delivery-accordion-row">
                        <li>{siteName}</li>
                        <li>{renderCheckMark(available)}</li>
                        {/*<li>{deliveryFrom}</li>*/}
                        <li>{isExposed === 'Yes' ? <T id="club.yes"/> : <T id="club.no"/>}</li>
                    </ul>
                ))
            }

        </div>
    )
};

export default Availbility;
