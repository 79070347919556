import gql from "graphql-tag";

export const AVAILABILITY_QUERY = gql`
    query availability($url: String!) {
        availability(url: $url) {
            ... on ProductInterface {
                availability {
                    available
                    deliveryFrom
                    isExposed
                    itemID
                    siteID
                    siteName
                    webSiteCode
                }
            }
        }
    }
`;